<template>
  <div>
    <treeview-root
      class="pl-2 pr-5"
      v-model="checkedGroupPaths"
      selectable
      selected-color="teal lighten-1"
      :items="items"
      :openItems="openItems"
      @input="handleCheck"
      item-key="groupPath"
      item-text="localization"
    />
  </div>
</template>

<script>
import TreeviewRoot from '@/components/Treeview/Treeview.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { convertGroupToFilterStructure } from '@/helpers/groupingHelper';

export default {
  name: 'GenericTreeDocumentTypes',

  props: {
    dashboard: {
      type: String,
      required: true,
    },
  },

  components: {
    TreeviewRoot,
  },

  data: () => ({
    checkedGroupPaths: [],
    showTooltip: false,
  }),

  computed: {
    ...mapGetters({
      allSearchParameters: 'allSearchParameters',
      searchParameterGroupPaths: 'searchParameterGroupPaths',
      userFilters: 'userFilters',
    }),

    filterType() {
      return `${this.dashboard}Grouping`;
    },

    getAllTimelineItems() {
      return `${this.dashboard}GetAllTimelineItems`;
    },

    items() {
      const grouping = this.userFilters[this.filterType];
      // Update grouping details for searchParameters
      const { name, version } = grouping;
      this.setGroupingDetails({ name, version });

      const items = grouping && Object.keys(grouping.groups)
        .map((groupCode) => convertGroupToFilterStructure(
          groupCode, grouping.groups[groupCode],
        ));
      return items;
    },

    openItems() {
      // get the groupPaths of the top level items
      return this.items.map((item) => item.groupPath);
    },
  },

  watch: {
    searchParameterGroupPaths(newGroupPaths) {
      // TODO: do we want to keep this filtering?
      // TODO: 6206 replace with BE-FED version(s)
      this.checkedGroupPaths = newGroupPaths || [];
    },
  },

  methods: {
    ...mapState(['searchParameters']),
    ...mapActions({
      setGroupPaths: 'setGroupPaths',
      setGroupingDetails: 'setGroupingDetails',
    }),

    async getTimelineItems() {
      await this.$store.dispatch(`${this.getAllTimelineItems}`);
    },


    async setQueryAndFilters(groupPaths) {
      // Set the selected filters as querystring of the dashboard
      const filters = {
        groupPaths: groupPaths ? groupPaths.join(',') : [],
      };

      await this.$router.push({
        name: `${this.dashboard}Dashboard`,
        query: { ...this.$route.query, ...filters },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log('route', err);
      });

      await this.getTimelineItems();
    },

    handleCheck() {
      const groupPaths = [
        ...this.checkedGroupPaths,
      ];
      this.setGroupPaths(groupPaths);
      this.setQueryAndFilters(groupPaths);
    },

    groupLocalization(groupPath, locale = 'nl') {
      const grouping = this.userFilters[this.filterType];
      if (grouping && grouping.groups[groupPath]) {
        const localization = grouping.groups[groupPath].localization[locale];
        return localization.plural || localization;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
