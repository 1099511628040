<template>
  <div>
    <v-container class="mt-n6">
      <v-row>
        <v-col>
          <v-subheader
            class="mb-n2 overline text-truncate"
          >
          {{$t('generic.period')}}
          </v-subheader>
          <v-card
            class="pa-2"
          >
            <!-- TODO: 6206 -->
            <filter-date-range
              :getTimelineItemsString="`${dashboard}GetAllTimelineItems`"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            {{ $t('generic.sources') }}
          </v-subheader>
          <v-card
            class="py-2"
          >
            <generic-tree-document-types :dashboard="dashboard" />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="my-14"
          >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FilterDateRange from '@/modules/documentDashboard/components/FilterDateRange.vue';
import GenericTreeDocumentTypes from '@/modules/documentDashboard/genericDashboard/components/GenericTreeDocumentTypes.vue';

export default {
  name: 'GenericSidebar',

  props: {
    dashboard: {
      type: String,
      required: true,
    },
  },

  components: {
    FilterDateRange,
    GenericTreeDocumentTypes,
  },

  computed: {
  },

};
</script>
