<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t('trends.trendAnalysis') }}
      <div class="text-right pr-4">
        <v-btn icon @click.stop.prevent="toggleTrendAnalyticsExpanded">
          <v-icon small v-if="trendAnalyticsExpanded">
            <!-- mdi-vector-arrange-below -->
            mdi-view-dashboard-outline
          </v-icon>
          <v-icon small v-if="!trendAnalyticsExpanded">
            <!-- mdi-vector-arrange-above -->
            mdi-view-agenda-outline
          </v-icon>
        </v-btn>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <apexchart
        type="area"
        height="400"
        :options="options"
        :series="series"
        @zoomed="getZoomedDataPoints"
        :style="`${isIE11 ? 'opacity: 0.4' : ''}`"
      />
      <div
        v-if="isIE11"
        style="
          height: 400px !important;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        "
        class="d-flex align-center justify-center"
      >
        <p class="ma-12 text-center font-weight-bold">
          {{ $t('trends.unsupportedIE') }}
        </p>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import VueApexCharts from 'vue-apexcharts';
import Config from '@/config';

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      initStartDate: null,
      initEndDate: null,
      dateRangeISO: {},
    };
  },

  props: {
    statisticsTrendType: {
      type: String,
      required: true,
    },
    statisticsTimelineType: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      trendAnalyticsExpanded: 'trendAnalyticsExpanded',
      userPreferences: 'userPreferences',
      isIE11: 'isIE11',
    }),

    statisticsTrend() {
      return this.$store.getters[this.statisticsTrendType];
    },

    setColors() {
      return this.statisticsTrend.groups.map((group) => group.color);
    },

    series() {
      const result = [];
      this.statisticsTrend.groups.forEach((group) => {
        result.push({
          name: group.localization,
          data: group.trends.slice(-Config.trendChartMaxPoints).map((a) => [
            // a.date,
            new Date(a.date).getTime(),
            a.documentCount,
          ]),
        });
        return null;
      });
      return result;
    },

    options() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
        chart: {
          offsetY: 10,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: 'zoom',
          },
          defaultLocale: this.userPreferences.locale,
          locales: [
            {
              name: this.userPreferences.locale,
              options: {
                months: [
                  this.$t('months.january'),
                  this.$t('months.february'),
                  this.$t('months.march'),
                  this.$t('months.april'),
                  this.$t('months.may'),
                  this.$t('months.june'),
                  this.$t('months.july'),
                  this.$t('months.august'),
                  this.$t('months.september'),
                  this.$t('months.october'),
                  this.$t('months.november'),
                  this.$t('months.december'),
                ],
                shortMonths: [
                  this.$t('monthsShort.january'),
                  this.$t('monthsShort.february'),
                  this.$t('monthsShort.march'),
                  this.$t('monthsShort.april'),
                  this.$t('monthsShort.may'),
                  this.$t('monthsShort.june'),
                  this.$t('monthsShort.july'),
                  this.$t('monthsShort.august'),
                  this.$t('monthsShort.september'),
                  this.$t('monthsShort.october'),
                  this.$t('monthsShort.november'),
                  this.$t('monthsShort.december'),
                ],
                days: [
                  this.$t('daysOfWeek.sunday'),
                  this.$t('daysOfWeek.monday'),
                  this.$t('daysOfWeek.tuesday'),
                  this.$t('daysOfWeek.wednesday'),
                  this.$t('daysOfWeek.thursday'),
                  this.$t('daysOfWeek.friday'),
                  this.$t('daysOfWeek.saturday'),
                ],
                shortDays: [
                  this.$t('daysOfWeekShort.sunday'),
                  this.$t('daysOfWeekShort.monday'),
                  this.$t('daysOfWeekShort.tuesday'),
                  this.$t('daysOfWeekShort.wednesday'),
                  this.$t('daysOfWeekShort.thursday'),
                  this.$t('daysOfWeekShort.friday'),
                  this.$t('daysOfWeekShort.saturday'),
                ],
                toolbar: {
                  download: 'Download',
                  selection: this.$t('generic.selection'),
                  selectionZoom: this.$t('generic.selectionZoom'),
                  // zoomIn: 'Zoom In',
                  // zoomOut: 'Zoom Uit',
                  // pan: 'Panning',
                  // reset: 'Reset Zoom',
                },
              },
            },
          ],
          animations: {
            enabled: true,
            easing: 'easeinout',
            animateGradually: {
              enabled: true,
              delay: 100,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 100,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 8,
          // Lets make this dynamic
          min: new Date(this.statisticsTrend.minDate).getTime(),
          max: new Date(this.statisticsTrend.maxDate).getTime(),
          position: 'bottom',
          floating: false,
          labels: {
            rotate: -15,
            style: {
              fontSize: '10px',
            },
            rotateAlways: true,
            formatter(val, timestamp) {
              return moment(new Date(timestamp)).format('D MMM YYYY');
            },
            minHeight: 54,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          title: {
            text: this.$t('trends.updatesAmount'),
          },
        },
        plotOptions: {
          area: {
            fillTo: 'bottom',
          },
        },
        events: {
          zoomed: (chartContext, dataPoints) => dataPoints,
        },
        tooltip: {
          theme: this.$vuetify.theme.dark ? 'dark' : 'light',
          x: {
            format: 'dd MMMM yyyy',
          },
        },
        legend: {
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'left',
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        colors: this.setColors,
        fill: {
          colors: this.setColors,
          opacity: 0.05,
          type: 'solid',
        },
        title: {
          text: this.$t('generic.poweredByPolpoNl'),
          align: 'right',
          margin: 0,
          offsetX: 0,
          offsetY: 12,
          floating: false,
          style: {
            fontSize: '10px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#D3D3D3',
          },
        },
      };
    },

    themeChanged() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    nothing() {},

    toggleTrendAnalyticsExpanded() {
      this.$store.dispatch('toggleTrendAnalyticsExpanded');
    },

    async getZoomedDataPoints(chartContext, dataPoints) {
      const zoomedDataPoints = await dataPoints;
      this.dateRangeISO.startDate = new Date(zoomedDataPoints.xaxis.min).toISOString();
      this.dateRangeISO.endDate = new Date(zoomedDataPoints.xaxis.max).toISOString();
      await this.$store.dispatch('setDateRange', this.dateRangeISO);
      await this.updateGraph();
    },

    updateGraph() {
      this.$store.dispatch(this.statisticsTimelineType);
    },
  },
};
</script>

<style lang="scss"> // Carefull, block not scoped
  .apexcharts-canvas {
    background: none !important;
  }
  svg.apexcharts-svg {
    background: transparent !important;
    // z-index: 10000;
  }
  .apexcharts-zoom-icon.selected {
    visibility: hidden !important;
  }
</style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
