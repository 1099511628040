<!--
  Component which is listed in the timeline list
-->

<template>
  <v-card
    v-if="documentTypesMixinReady"
    class="mx-auto mb-2 no-focus"
    min-height="100"
    outlined
    @click.stop.prevent="cardClicked()"
  >
    <v-card-title
      class="text-subtitle-1"
    >
      <div class="titleRow">
        <div>
          <span class="d-inline font-weight-medium">{{ documentType }}</span>
          – {{ title }}<span v-if="fragDocu"> {{ computedFragDocufy }}</span>
        </div>

        <svg
          v-if="getDocTypeByDocTypeId(type).aiContent"
          class="aiIcon"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :d="svgIcons.ai"
            transform="scale(0.025, 0.025)"
          />
        </svg>
      </div>
    </v-card-title>

    <!-- NL -->
    <v-card-text
      v-if="documentText"
      class="mt-n2 mb-n4"
    >
      {{ computedQuotify }}
    </v-card-text>
    <v-card-text
      v-if="subjects && subjects.length > 0"
      class="mt-n2 mb-n4"
    >
    </v-card-text>

    <v-card-text v-if="subjects && subjects.length > 0" class="mt-n2 mb-n4">
      <strong>{{ $t('generic.topics') }}</strong><br>
      {{ subjectsAsString }}
    </v-card-text>
    <!-- /NL -->

    <!-- EU -->
    <v-card-text v-if="epCommittees" class="mt-n2 mb-n6">
      {{ epCommittees }}
    </v-card-text>

    <v-card-text v-if="isEcOrEp && eventDate" class="mt-n2 mb-n6">
      <strong>{{ $t('timeline.meetingDate') }}: </strong>{{ eventDate }}
    </v-card-text>

    <v-card-text v-if="epReference" class="mt-n2 mb-n6">
      <strong>{{ $t('timeline.referenceNumber') }}: </strong>{{ epReference }}
    </v-card-text>

    <v-card-text v-if="epProcedure" class="mt-n2 mb-n6">
      <strong>{{ $t('timeline.procedureNumber') }}: </strong>{{ epProcedure }}
    </v-card-text>
    <!-- /EU -->

    <!-- LOCREG -->
    <!-- Here we put the entries for nlLr Dashboard -->
    <v-card-text v-if="dlrcMeetingsPresent && meetings[0].url" class="mt-n2 mb-n6">
      <strong>{{ $t('generic.meeting') }}:&nbsp;
      </strong>
      <a
        :href="meetings[0].url"
        target="_blank"
        class="text--primary text-decoration-none"
      >
        {{ meetings[0].subject }}
      </a>
    </v-card-text>

    <v-card-text v-if="dlrcAgendaItems && dlrcAgendaItems.length > 0" class="mt-n2 mb-n6">
      <strong>{{ $t('generic.agendaItem') }}: </strong>
      <a
        :href="dlrcAgendaItems[0].url"
        target="_blank"
        class="text--primary text-decoration-none"
      >
        {{ dlrcAgendaItems[0].title }}
      </a>
    </v-card-text>
    <!-- /LOCREG ??? -->

    <v-card-text v-if="this.related?.voteResults?.overallResult" class="mt-n2 mb-n6">
      <strong> {{ $t('generic.status')}}: </strong>
      <span :style="voteResultStatusStyling(this.related?.voteResults?.overallResult)">
        {{ this.related?.voteResults?.overallResult }}
      </span>
      <span v-if="this.related?.voteResults?.resultDate">({{ this.related.voteResults.resultDate }})</span>
    </v-card-text>


    <v-card-text v-if="url" class="mt-n2 mb-n4">
      {{ $t('generic.source') }}: <a
        :href="url"
        target="_blank"
        class="text--primary text-decoration-none"
      >{{ computedUrlToSourceName }}</a>
    </v-card-text>
    <v-card-text
      v-if="isNotEuAndNotDlrc && !isRSSEU && (sourceUrl && !url)"
      class="mt-n2 mb-n4"
    >
    {{ $t('generic.source') }}:
      <span
        v-if="!url"
        @click.stop.prevent="openSourceUrlInNewWindow(sourceUrl)"
      >{{ computedSourceUrlToSourceName }}</span>
    </v-card-text>

    <v-card-actions class="mt-n2">
      <list-timeline-item-category
        :type="type"
        :percentage="match"
        :institution="institution"
        :sourceUrl="sourceUrl"
      />
      <v-spacer />

      <!-- <v-btn text
        outlined
        @click="showTeaserDialog()"
        v-if="!authHasModuleAccess('MC04')"
      >
        Aan lijst toevoegen
      </v-btn> -->
      <v-btn
        text
        outlined
        @click.stop.prevent="addToList()"
        v-if="authHasModuleAccess('MC04')"
      >
        + {{$t('generic.list')}}
      </v-btn>

      <!-- <v-btn v-if="!url" text :to="{ path: actionUrl }" outlined>Details</v-btn> -->
      <v-btn
        v-if="!url"
        text
        @click.stop.prevent="goToUrlConditionally()"
        outlined
      >
        {{ $t('timeline.details') }}
      </v-btn>
      <v-btn
        v-if="url"
        text
        @click.stop.prevent="openUrlInNewWindow()"
        outlined
      >
        {{ $t('timeline.showMessage')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import useDocumentTypes from '@/composables/documentTypesComposable';
import { mapGetters } from 'vuex';
import PropValidators from '@/propValidators';
import ListTimelineItemForm from '@/components/ListTimelineItemForm.vue';
import FeatureTeaserListManagerVue from '@/components/FeatureTeaserListManager.vue';
import { showDialog } from '@/helpers/dialogHelper';
import fragDocufy from '@/helpers/fragDocufyHelper';
import quotify from '@/helpers/quotifyHelper';
import urlToSourceName from '@/helpers/urlToSourceNameHelper';
import { voteResultStatusStyling } from '@/helpers/voteResultsHelper';
import svgIcons from '@/assets/svg_icons';

import ListTimelineItemCategory from './ListTimelineItemCategory.vue';

export default {
  name: 'ListTimelineItem',

  components: {
    ListTimelineItemCategory,
  },

  setup() {
    const {
      documentTypesMixinReady,
      getDocTypeByDocTypeId,
      getLocalizationForDocTypeId,
      findDocumentTypesByLabels,
      modulesMeetDocumentTypeRequirements,
      getPrunedDocumentTypesByModules,
      getAllSupportedEuroparlCodes,
      getAllEuroparlDocumentTypesPerInstitution,
    } = useDocumentTypes();

    return {
      documentTypesMixinReady,
      getDocTypeByDocTypeId,
      getLocalizationForDocTypeId,
      findDocumentTypesByLabels,
      modulesMeetDocumentTypeRequirements,
      getPrunedDocumentTypesByModules,
      getAllSupportedEuroparlCodes,
      getAllEuroparlDocumentTypesPerInstitution,
    };
  },

  props: {
    dashboard: {
      type: String,
      required: true,
    },
    // Document id
    id: {
      type: String,
      required: true,
    },
    // Document title
    title: {
      type: String,
      required: true,
    },
    // Date
    date: {
      type: String,
      required: true,
    },
    // Document text
    documentText: {
      type: String,
      required: false,
    },
    // Document url
    url: {
      type: String,
      required: false,
    },
    // Document sourceUrl in case of non rss docs
    sourceUrl: {
      type: String,
      required: false,
    },
    // Document type
    type: {
      type: String,
      required: true,
      default: 'default',
    },
    // Matching percentage
    match: {
      required: false,
      validator: PropValidators.percentage,
    },
    // Word highlighted in document
    highlightWord: {
      type: String,
      required: true,
    },
    // Subjects of document
    subjects: {
      type: Array,
      required: false,
    },
    // metadata
    metadata: {
      type: Object,
      required: false,
    },
    // related
    related: {
      type: Object,
      required: false,
    },
    // EU institution
    institution: {
      type: String,
      required: false,
    },
    // EU fragDocu
    fragDocu: {
      type: String,
      required: false,
    },
    // EU committees
    committees: {
      type: Array,
      required: false,
    },
    // EU relations
    relations: {
      type: Array,
      required: false,
    },
    // EU authorities
    authorities: {
      type: String,
      required: false,
    },
    // EU eventDate
    eventDate: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      svgIcons,
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      userFilters: 'userFilters',
      userPreferences: 'userPreferences',
    }),

    computedQuotify() {
      return quotify(this.documentText);
    },

    computedFragDocufy() {
      return fragDocufy(this.fragDocu);
    },

    computedUrlToSourceName() {
      return urlToSourceName(this.url);
    },

    computedSourceUrlToSourceName() {
      return urlToSourceName(this.sourceUrl);
    },

    isEcOrEp() {
      return this.institution && (this.institution === 'EC' || this.institution === 'EP');
    },

    isDlrc() {
      return this.type.startsWith('CC-') || this.type.startsWith('PC-');
    },

    isBeFed() {
      return this.type.startsWith('BE-FED');
    },

    isBeFlm() {
      return this.type.startsWith('BE-FLM');
    },

    dlrcMeetingsPresent() {
      return this.isDlrc && this.meetings && this.meetings.length > 0;
    },

    isNotEuAndNotDlrc() {
      return (!this.isEcOrEp && !this.isDlrc);
    },

    epCommitteesList() {
      const { euEpCommittees } = this.userFilters;
      return euEpCommittees;
    },

    actionUrl() {
      const highlightedWord = encodeURIComponent(this.highlightWord);
      let destination;
      if (this.dashboard === 'nl') {
        destination = '/nl/document';
      } else if (this.dashboard === 'eu') {
        destination = '/eu/document';
      } else if (this.dashboard === 'nlLr') {
        destination = '/nl-lr/document';
      } else if (this.dashboard === 'beFed') {
        destination = '/be-fed/document';
      } else if (this.dashboard === 'beFlm') {
        destination = '/be-flm/document';
      } else throw new Error(`Invalid value for 'dashboard': ${this.dashboard}`);
      return `${destination}/${this.id}/${highlightedWord}`;
    },

    dateAsString() {
      return this.$moment(this.date).format('DD-MM-YYYY');
    },

    eventDateAsString() {
      return this.$moment(this.eventDate).format('d MMMM YYYY');
    },

    subjectsAsString() {
      const keywords = this.subjects.map((sub) => sub.keyword);
      return keywords.join(', ');
    },

    documentType() {
      const loc = this.getLocalizationForDocTypeId(
        this.type,
        { locale: this.userPreferences.locale },
      );
      return typeof loc === 'string' ? loc : loc.singular;
    },

    epCommittees() {
      const result = [];
      if (this.committees) {
        this.committees.forEach((item) => {
          this.epCommitteesList.forEach((obj) => {
            if (obj.code === item) {
              result.push(obj.nl);
            }
          });
        });
      }
      return result.toString();
    },

    epReference() {
      const reference = [];
      if (this.relations) {
        this.relations.forEach((item) => {
          if (item.type === 'DOSSIER') {
            reference.push(item.code);
          }
        });
      }
      return reference.toString().replace(',', ', ');
    },

    epProcedure() {
      const procedure = [];
      if (this.relations) {
        this.relations.forEach((item) => {
          if (item.type === 'PROCEDURE') {
            procedure.push(item.code);
          }
        });
      }
      return procedure.toString().replace(',', ', ');
    },

    meetings() {
      if (!this.related) return [];
      const meetings = [];
      if (this.related.meetings && this.related.meetings.length > 0) {
        this.related.meetings.forEach((item) => {
          item.meeting = true;
          meetings.push(item);
        });
      }
      return meetings;
    },

    dlrcAgendaItems() {
      if (!this.related) return [];
      const dlrcAgendas = [];
      if (this.related.agendaItems && this.related.agendaItems.length > 0) {
        this.related.agendaItems.forEach((item) => {
          item.agendaItem = true;
          dlrcAgendas.push(item);
        });
      }
      return dlrcAgendas;
    },
  },

  methods: {
    voteResultStatusStyling,

    showTeaserDialog() {
      showDialog(FeatureTeaserListManagerVue);
    },

    cardClicked() {
      return this.url ? this.openUrlInNewWindow() : this.goToUrlConditionally();
    },

    goToUrlConditionally() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: this.actionUrl });
      } else {
        window.open(this.actionUrl, '_blank');
      }
    },

    openUrlInNewWindow() {
      window.open(this.url, '_blank');
    },

    openSourceUrlInNewWindow(url) {
      window.open(url, '_blank');
    },

    addToList() {
      this.$gtag.event('timeline-update-add-to-list', {
        event_category: 'timeline',
      });

      showDialog(ListTimelineItemForm, {
        document: {
          id: this._props.id,
          date: this._props.date,
          type: this._props.type,
          sourceUrl: this._props.sourceUrl,
        },
        title: this._props.title,
      });
    },

    isRSSEU() {
      return this.type.startsWith('SB-EU-') || this.type.startsWith('MD-EU-');
    },
  },

};
</script>

<style lang="scss" scoped>
.no-focus:focus::before {
  opacity: 0 !important;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .aiIcon {
    margin-left: 1em;
    flex-grow: 0;
    flex-shrink: 0;
    width: 26px;
    height: 26px;

    path {
      fill: teal;
    }
  }
}
</style>
